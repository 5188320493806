import React, { useState } from "react";
import logo from "../assets/logo-white.png";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import BackdropWrapper from "./modals/BackdropWrapper";
import ContactForm from "./modals/ContactForm";

export default function Navbar() {
  const [contactBackdrop, setContactBackdrop] = useState(false);
  const navigate = useNavigate();

  const backdropHandler = () => {
    setContactBackdrop((current) => !current);
  };

  return (
    <StyledNavbar>
      <BackdropWrapper
        open={contactBackdrop}
        smallSize={true}
        backdropHandler={backdropHandler}
        element={<ContactForm backdropHandler={backdropHandler} />}
      />
      <div className="logo" onClick={(e) => navigate("/")}>
        <img src={logo} alt="" />
      </div>
      <div className="nav-items">
        <div className="item">
          <Link to="/blog">Blog</Link>
        </div>
        <div className="item">
          <Link to="/upgrade">Upgrade</Link>
        </div>
        <div onClick={backdropHandler} className="item">
          <h5>Contact Us</h5>
        </div>
      </div>
    </StyledNavbar>
  );
}

const StyledNavbar = styled.section`
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5%;
  background-color: var(--primary-color);
  width: 100%;
  padding: 0 2%;
  .logo {
    width: 200px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav-items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    white-space: nowrap;
    .item {
      cursor: pointer;
      a,
      h5 {
        font-size: 1.2rem;
        font-size: 600;
        color: white;
        &:hover {
          color: var(--text-hover-color);
        }
      }
    }
  }
`;
